.dashboard-menu-header>div>i.icon-chevron-open {
  transform: rotate(180deg);
  transition: all ease-in-out 250ms;
  align-items: center
}

.dashboard-menu-header>div>i.icon-chevron-close {
  transform: rotate(0deg);
  transition: all ease-in-out 250ms;
  align-items: center
}