.created-by {
  display: flex;
}

.created-by p:first-child {
  margin: 0px;
  color: var(--color-text-light);
  margin-right: 0.5rem;
  font-size: .8rem;
}

.created-by p:last-child {
  margin: 0px;
  color: var(--color-black);  
  font-size: .8rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}