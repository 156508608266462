.logs {
  font-family: 'Inter', sans-serif;
  padding: 2% 3%;
  width: 100%;
}

.logs .logs-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-bottom: 1rem;
}

.logs .logs-header .title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: aqua;
  padding: 2rem 0 2rem;
  width: 100%;
}

.logs .logs-header .title h1{
  font-weight  : 700;
  font-size    : 32px;
  color        : var(--color-active);
  margin: 0 0 0;
}

.logs .logs-header .title .title-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.logs .logs-menu {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.logs .logs-dropdown {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.logs .logs-dropdown .child-dropdown {
  margin-right: 1rem ;
  width: max-content;
}

.logs .logs-download {
  display: flex;
}

.logs .logs-dropdown span {
  color: var(--color-active);
  font-size: 1em;
  font-weight: 500;
  margin-right: 0.5rem;
}

.logs .ant-picker {

  /* width: 12rem !important; */
  padding: 0.8rem 1rem;
  
  /* background-color: aqua; */
  border-color: var(--color-light-grey) !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}
.logs .logs-dropdown .ant-select {
display: inline-block;
  width: auto !important;
  /* background-color: aqua; */
  border-color: var(--color-black) !important;
  border-radius: 0.5rem !important;
  color: var(--color-active);
}
.logs .logs-dropdown .ant-select-selector {
  display: inline-flex;
  height: 3rem !important;
  padding: 0.5rem 1.5rem !important;
  min-width: 12rem;
  width: auto !important;
  margin: 0 auto;
  align-items: center;
  font-weight: 600;
  font-size: 1rem ;
  background-color: transparent !important;
  color: var(--color-active);
}
.logs .logs-dropdown .ant-select-selection-item {
 display: flex;
 align-items: center;
}

.logs .ant-pagination-options-size-changer {
  font-size: 0.8rem !important;
}


.logs .search-bar, .logs .logs-menu > .logs-search .ant-picker {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--color-border) !important;
  border-radius: 0.25rem !important;
  font-family: 'Inter', sans-serif;
  font-size: .9rem;
  padding: 0.5rem 1rem;
  margin-right: 1rem
}

.logs .logs-menu > .logs-search .ant-picker.ant-picker-focused {
  border-color: var(--color-input-focus) !important;
  box-shadow: none !important;
}

.logs .search-bar input::placeholder {
  color: var(--color-text-light) !important;
}

.logs .search-bar input:focus {
  outline: none !important;
  box-shadow: none !important;
  border-color: var(--color-input-focus) !important;
}

.logs .dropdown-tree {
  display: flex;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-secondary);
  padding: 0.3rem 0.7rem;
  margin-right: 1rem;
  width: 18%;
}

.logs .search-bar i.feather-search::before  {
  height: 16px;
  width: 16px;
  color: var(--color-secondary);
}



.logs .search-bar input {
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 0.9rem;
  padding-left: 1rem;
  color: var(--color-active);
}

.logs .search-bar input::placeholder {
  color: var(--color-secondary) !important;
}

.logs .ant-pagination-options-size-changer.ant-select {
  width: 8rem !important;
}

.logs table th {
  background-color: var(--color-navbar) !important;
  color: var(--color-black);
}

.logs table .btn {
  color: hsla(0, 0%, 31%, 1);
  background-color: transparent;
  outline: none;
  border: none;
}

.logs table tr {
  font-size: 0.8rem;
}

.logs table td.ant-table-cell {
  padding: 0.5rem;
}

.logs table .btn i {
  margin-right: 0.2rem;
}

.logs table .btn:hover {
  cursor: pointer;
}

.logs table .btn--edit .btn--edit:hover {
  color: var(--color-primary);
}
.logs table .btn--access .btn--access:hover {
  color: green;
}
.logs table .btn--delete .btn--delete:hover {
  color: red;
}

/* .logs .logs-limit {
  margin-bottom: 1rem;

} */

.logs .logs-date-picker {
  width: auto;
  height: max-content;
}

/* .logs .logs-btn button { */
  /* background: var(--color-active);
  border-radius: 5px;
}

.logs .logs-btn button:hover {
  opacity: 0.8;
} */

.logs .logs-search {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
