.card-wrapper {
  display: grid;
  gap: 1rem;
  grid-template-columns: 32% 32% 32%;
}

.data-card {
  flex: 0 0 100%;
  background-color: #fff;
  margin-bottom: 1rem;
  border-bottom: 1px solid var(--color-stroke);
  border-radius: 5px;
  justify-content: space-between;
  align-items: flex-start;
  cursor: pointer;
  flex-direction: column;
  flex: 1;
}

.data-card:hover,
.data-card.active {
  box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -webkit-box-shadow: 10px 3px 22px -1px rgb(0 0 0 / 18%);
  -moz-box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
}

.data-card div {
  display: flex;
  border-bottom: none !important;
}

.card-data-header {
  background: var(--header-color) !important;
  display: flex;
  width: 100%;
  font-weight: 400;
  font-size: 10px;
  border-radius: 5px 5px 0 0;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  color: var(--color-text-placeholder);
}

.card-date {
  border-radius: 5px !important;
  text-align: center;
}

.data-card .card-data {
  width: 100%;
  padding: 0 1rem;
}

.data-card .card-line {
  flex-grow: 1;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  border: 1px solid #EEEEEE;
  width: 100%;
}

.data-card .card-bottom {
  margin-top: auto;
  display: flex;
  padding: 0 1rem 0.5rem 1rem;
  justify-content: space-between;
  width: 100%;
}

.data-card .card-bottom .info {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #FC7318;
}

.data-card .actions {
  align-items: center;
  font-weight: 400;
  font-size: 12px;
}

.data-card div img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  margin-right: 1.2rem;
}

.data-card div.details {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.data-card div.details div.title-wrapper {
  width: 100%;
  line-height: 30px;
  padding: 5px 0;
  position: relative;
  font-size: 1.2rem;
}

.data-card div.details h3 {
  margin-bottom: 0.2rem;
  color: var(--color-active);
  font-weight: 500;
  font-size: 1.2em;

  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.data-card div.details p {
  margin-bottom: 0.2rem;
  color: var(--color-secondary);
}

.data-card div button,
.data-card div a {
  border: none;
  background-color: transparent;
  font-size: 1.2em;
  color: var(--color-secondary);
  cursor: pointer;
}

.data-card div button:first-child,
.data-card div a:first-child {
  margin-right: 0.2rem;
}

.table-date-card {
  align-self: center;
  background: #E9EDF5 !important;
  border-radius: 5px !important;
  width: 180px !important;
  text-align: center;
}

.date-text {
  align-self: center;
  text-align: center;
  justify-content: center;
}