.programme {
  // modify antd dropdown select
  .programme-dropdown {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 1rem;
    width: 50%;
  }

  .programme-dropdown span {
    color: var(--color-active);
    font-size: 1em;
    font-weight: 500;
    margin-right: 0.5rem;
  }

  .ant-select {
    display: flex;
    width: 15% !important;
    border-radius: 0.5rem !important;
    color: var(--color-active);
  }

  .ant-select-selector {
    background-color: transparent !important;
  }

  .button-primary {
    border: none;
    height: 100%;
    padding: 0.5rem 1.5rem;
    color: #fff;
    background-color: var(--color-primary);
    border-radius: 5px;
  }
  &__nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.2rem;
    align-items: center;
  }

  &__nav-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    width: 50%;
  }

  &__search-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-direction: column;
    margin-right: 1rem;
  }

  &__search {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    border: 1px solid var(--color-secondary);
    padding: 0.3rem 0.7rem;

    i {
      font-size: 1.5em;
      color: var(--color-active);
    }

    input {
      outline: none;
      border: none;
      background-color: transparent;
      padding-left: 1rem;
      color: var(--color-active);

      &::placeholder {
        color: var(--color-secondary);
      }
    }
  }

  &__content {
    width: 100%;
    column-gap: 1rem;
    display: flex;
    flex-wrap: wrap;
  }

  &__card {
    width: 24%;
    display: flex;
    background-color: #fff;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--color-stroke);
    border-radius: 5px;
    justify-content: space-between;
    padding: 1rem;
    align-items: flex-start;
    cursor: pointer;

    &:hover {
      box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
      -webkit-box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
      -moz-box-shadow: 10px 3px 22px -1px rgba(0, 0, 0, 0.18);
    }
  }

  &__details {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__title {
    margin-bottom: 0.2rem !important;
    color: var(--color-active) !important;
    font-weight: 500 !important;
    font-size: 1.2em !important;
  }

  &__category {
    margin-bottom: 0.2rem !important;
    color: var(--color-secondary) !important;
  }

  button {
    border: none;
    background-color: transparent;
    color: var(--color-secondary);
    cursor: pointer;
  }

  &__pagination {
    display: flex;
    justify-content: flex-end;
  }

  &__empty-state {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
  }

  &__header {
    background-color: var(--color-primary);
  }

  &__header:first-child {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__header:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  &__header .ant-collapse-header:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transition: 0.3s all ease;
  }

  &__header .ant-collapse-header {
    color: #fff !important;
  }

  &__card {
    /* width: 25%; */
    flex: 0 0 100%;
    /* width: 312px; */
    display: flex;
    background-color: #fff;
    margin-bottom: 1rem;
    border-bottom: 1px solid var(--color-stroke);
    border-radius: 5px;
    justify-content: space-between;
    padding: 1rem;
    align-items: flex-start;
    cursor: pointer;

    @media (min-width: 1120px) {
      flex: 0 0 400px;
    }
  }
}
