.page-title {
    /* Heading 4 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: inherit;
    margin-top: 10px;
    margin-bottom: 20px;
}

.overview-title {
    /* Heading 4 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    color: white;
    margin-top: 10px;
    margin-bottom: 20px;
}