
.media-date {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.media-date > span {
  margin-left: 0.5rem;
  font-weight: 600;
  font-size: .8rem;
  color: var(--action-color);
  font-family: 'Inter', sans-serif;
}