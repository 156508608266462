.sidebar {
    /* background-color: #fff; */
    background: #163C50;
    background-image: url(../../assets/bg-sidebar.png);
    background-repeat: no-repeat;
    background-position: bottom right;
    background-size: contain;
    
    padding: 2% 0 1% 0;
    /* margin-right: -4px; */
    /* box-shadow: 0px 0px 2px 6px rgba(0, 0, 0, 0.04); */
    z-index: 4;
    width: 236px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    height: 100vh;
    overflow: hidden;
    overflow-y: hidden;
    position: fixed;
}

.sidebar:hover {
    overflow-y: auto;
    /* padding: 2% 0 1% 0; */
    transition: overflow ease-in-out 750ms;
    border-right: none;
}

.sidebar::-webkit-scrollbar {
    width: 0;
    height: 0;
    /* margin-right: -5px; */
}

/* Track */
.sidebar::-webkit-scrollbar-track {
    background: var(--color-bg-primary);
}

/* Handle */
.sidebar::-webkit-scrollbar-thumb {
    background: var(--color-navbar-active-border);
    border-radius: 4px;
}

.sidebar>.sidebar-top>img {
    margin: auto;
    max-width: 80px;
    display: flex;
}


/* new sidebar */
.menu-sidebar {
    padding-left: 0;
}

.menu-sidebar > ul {
    padding: 0px.7rem;
    backdrop-filter: blur(10px);
}

.menu-sidebar>ul>li>a {
    /* color: var(--color-black); */
    color: var(--color-text-gray-light);
    padding: 1rem 0px 1rem 1rem;
    display: flex;
}

.menu-sidebar>li.sidebar-menu-item {
    margin-left: 0rem;
    color: var(--color-text-gray-dark);
    /* text-transform: uppercase; */
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: .8rem;
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
    background-color: none;
    border-right: none;
}

.menu-sidebar>li.sidebar-menu-item>a {
    /* color: var(--color-black); */
    color: var(--color-text-gray-light);
    /* padding: 1rem 0px 0rem 1rem; */
    padding: 0.8rem 1rem;
    display: flex;
    align-items: center;
}

.menu-sidebar>li.sidebar-menu-item>a>div {
    padding-left: 0.3rem;
    margin-left: .5rem;
}

.menu-sidebar>li.sidebar-menu-item>a:hover,
.menu-sidebar>li.sidebar-menu-item.active>a:not(.has-children) {
    /* background-color: var(--color-stroke); */
    background-color: var(--color-bg-navbar-active);
    padding: 0.8rem 1rem;
    border-right: 4px solid var(--color-navbar-active-border);
}


.menu-sidebar>ul>li:first-child {
    border-radius: 6px 6px 0px 0px;
}

.menu-sidebar>ul>li:last-child {
    border-radius: 0px 0px 6px 6px;
}

.menu-sidebar>ul>li>a {
    display: flex;
    align-items: center;
    position: relative;
    /* margin: 0.5rem 0rem; */
    cursor: pointer;
    /* border-radius: 10px; */
    padding: 0.8rem 1rem;
}

.menu-sidebar>ul>li>a:not(.has-children):hover,
.menu-sidebar>ul>li.active>a:not(.has-children) {
    /* background-color: var(--color-stroke); */
    background-color: var(--color-bg-navbar-active);
    padding: 0.8rem 1rem;
    border-right: 4px solid var(--color-navbar-active-border);
}

.menu-sidebar>ul>li>a:not(.has-children):hover>i,
.menu-sidebar>ul>li>a:not(.has-children):hover>div,
.menu-sidebar>ul>li.active>a:not(.has-children)>i,
.menu-sidebar>ul>li.active>a:not(.has-children)>div {
    color: #fff;
}

.menu-sidebar>ul>li>a:not(.has-children) {
    /* padding: .25em .5em; */
}

.menu-sidebar>ul>li>a>i {
    color: var(--color-navbar);
}

.menu-sidebar>ul>li>a>div,
.menu-sidebar>ul>li>div {
    color: var(--color-text-gray-light);
    font-family: 'Inter', sans-serif;
    padding: 0px 1rem;
    font-size: .8rem;
    font-weight: 500;
}

.menu-sidebar>ul>li>div.menu-has-children {
    display: flex;
    padding: 0;
    flex-direction: column;
    width: 100%;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 1rem;
    /* border-radius: 10px; */
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-parent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    /* font-size: 14px !important; */
    padding: 1rem;
    font-weight: 600;
    /* border-radius: 10px; */
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-parent > div {
    color: #fff;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-parent > i.icon-chevron-open {
    transform: rotate(180deg);
    transition: all ease-in-out 250ms;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-parent > i.icon-chevron-close {
    transition: all ease-in-out 250ms;
    transform: rotate(0deg);
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children:hover,
.menu-sidebar>ul>li.has-children.active>div.menu-has-children>.menu-children {
    /* background-color: var(--color-stroke); */
    background-color: var(--color-bg-navbar-active);
    padding: 0.8rem 1rem;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children>div {
    display: flex;
    align-items: center;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children>div>i,
.menu-sidebar>ul>li>div.menu-has-children>.menu-children>i {
    color: var(--color-text-gray-light);
    font-size: 1rem;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children>i.icon-chevron-open {
    transform: rotate(180deg);
    transition: all ease-in-out 250ms;

}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children>i.icon-chevron-close {
    transform: rotate(0deg);
    transition: all ease-in-out 250ms;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children:hover>div>i,
.menu-sidebar>ul>li.has-children.active>div.menu-has-children>.menu-children>div>i {
    /* color: var(--color-primary); */
    color: #fff;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children>div>div {
    margin-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-children:hover>div>div,
.menu-sidebar>ul>li.has-children.active>div.menu-has-children>.menu-children>div>div {
    color: var(--color-primary);
    color: #fff;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children {
    position: relative;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li {
    /* padding: 1rem 0px 1rem 3.5rem; */
    cursor: pointer;
    border-right: 4px solid transparent;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li>a {
    /* color: var(--color-black); */
    color: var(--color-text-gray-light);
    padding: 1rem 0px 1rem 1rem;
    display: flex;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li>a>div {
    padding-left: 0.3rem;
    margin: 3px 0;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li:hover
/* .menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li.active  */
{
    background-color: var(--color-bg-navbar-children-active);
    border-right: 4px solid var(--color-navbar-active-border);
    /* margin-right: -4px; */
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li:hover>a,
.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children>li.active>a {
    color: #fff;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children.menu-sidebar-children-close {
    opacity: 0;
    z-index: -2;
    height: 0;
    transition: opacity ease-in-out 250ms, height 250ms ease-in-out 150ms;
}

.menu-sidebar>ul>li>div.menu-has-children>.menu-sidebar-children.menu-sidebar-children-open {
    opacity: 1;
    z-index: 1;
    height: 100%;
    transition: opacity 250ms ease-in-out, height 250ms ease-in-out 150ms;
}