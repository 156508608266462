.submenu-has-children {
    display: flex;
    padding: 0;
    flex-direction: column;
    width: 100%;
}

.menu-sidebar-subtitle {
    /* width: 90%; */
    /* margin-left: 0.7rem; */
    color: #fff !important;
    /* text-transform: uppercase; */
    font-family: 'Inter', sans-serif !important;
    font-weight: 600;
    font-size: .65rem;
    /* letter-spacing: .1rem; */
    background: rgba(255, 255, 255, 0.06);
    /* Inside auto layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: .2rem;
}

.submenu-has-children>.submenu-children {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 0.8rem 1rem;
    color: #fff;
    /* border-radius: 10px; */
}

.submenu-has-children>.submenu-parent {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    font-size: 14px !important;
    padding: 0.5rem;
    /* border-radius: 10px; */
}

.sub-has-children.active {
    border-right: 4px solid var(--color-bg-navbar-active) !important;
}

.submenu-has-children>.submenu-children:hover,
.sub-has-children.active>div.submenu-has-children>.submenu-children {
    /* background-color: var(--color-stroke); */
    background-color: var(--color-bg-navbar-active);
    padding: 0.8rem 1rem;
}

.submenu-has-children>.submenu-children>div {
    display: flex;
    align-items: center;
}

.submenu-has-children>.submenu-children>div>i,
.submenu-has-children>.submenu-children>i {
    color: var(--color-text-gray-light);
    font-size: 1rem;
}

.submenu-has-children>.submenu-children>i.icon-chevron-open {
    transform: rotate(180deg);
    transition: all ease-in-out 250ms;

}

.submenu-children>i.icon-chevron-close {
    transition: all ease-in-out 250ms;
    transform: rotate(0deg);
}

.submenu-has-children>.submenu-children:hover>div>i,
li.has-children.active>div.submenu-has-children>.submenu-children>div>i {
    /* color: var(--color-primary); */
    color: #fff;
}

.submenu-has-children>.submenu-children>div>div {
    margin-left: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 120px;
}

.submenu-has-children>.submenu-children:hover>div>div,
li.has-children.active>div.submenu-has-children>.submenu-children>div>div {
    color: var(--color-primary);
    color: #fff;
}

.submenu-has-children>.submenu-sidebar-children {
    position: relative;
}

.submenu-has-children>.submenu-sidebar-children>li {
    /* padding: 1rem 0px 1rem 3.5rem; */
    cursor: pointer;
}

.submenu-has-children>.submenu-sidebar-children>li>a {
    /* color: var(--color-black); */
    color: var(--color-text-gray-light);
    padding: 1rem 0px 1rem 1rem;
    display: flex;
    margin-left: 1.5rem;
}

.submenu-has-children>.submenu-sidebar-children>li>a>div {
    padding-left: 0.3rem;
    margin: 3px 0;
}


.submenu-has-children>.submenu-sidebar-children>li:hover,
.submenu-has-children>.submenu-sidebar-children>li.active {
    background-color: var(--color-bg-navbar-children-active);
    border-right: 4px solid var(--color-navbar-active-border);
}

.submenu-has-children>.submenu-sidebar-children>li:hover>a,
.submenu-has-children>.submenu-sidebar-children>li.active>a {
    color: #fff;
}

.submenu-has-children>.submenu-sidebar-children.submenu-sidebar-children-close {
    opacity: 0;
    z-index: -2;
    height: 0;
    transition: opacity ease-in-out 250ms, height 250ms ease-in-out 150ms;
    display: none;
}

.submenu-has-children>.submenu-sidebar-children.submenu-sidebar-children-open {
    opacity: 1;
    z-index: 1;
    height: 100%;
    transition: opacity 250ms ease-in-out, height 250ms ease-in-out 150ms;
    display: block;
}